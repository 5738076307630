export default {
  table: {
    ACTION: [
      {
        title: 'Actualizar',
        dataIndex: 'update',
        key: 'update',
      },
      {
        title: 'Eliminar',
        dataIndex: 'delete',
        key: 'delete',
      }
    ]
  }
}