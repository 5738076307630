import Follow from './Follow'
import Interaction from './Interaction'
import Publication from './Publication'
import Survey from './Survey'

export default {
  0: Interaction,
  1: Publication,
  2: Follow,
  3: Survey,
}
