import Follow from './Follow'
import Interaction from './Interaction'
import Report from './Report'
import DirectInteraction from './DirectInteraction'

export default {
  0: Interaction,
  1: Follow,
  2: Report,
  3: DirectInteraction,
}
